@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #000000;
}

/*@font-face {
font-family: Metropolis;
src: url(../../fonts/Metropolis-Regular.otf);
font-weight: 500;
}

@font-face {
font-family: Metropolis;
src: url(../../fonts/Metropolis-Medium.otf);
font-weight: 700;
}*/
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

ul {
  list-style: none;
}

.nostyle {
  padding: 0;
  background-color: transparent;
  margin: 0;
  width: auto;
}


.edit-btn {
  margin-left: 5px;
}

header {
  height: 80px;
  background: #ffffff;
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 27px 16px 21px;
  position: fixed;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
}

header .logo {
  /*margin: 5px 0px 6px;*/
  font-size: 0;
  display: inline-block;
}

header .file-name {
  margin-left: 30px;
  display: inline-block;
}

header .file-name input {
  line-height: 2.4;
  font-size: 16px;
  outline: none;
  border: 1px solid transparent;
  border-width: 0 0 1px;
  padding: 0px;
  width: 260px;
  font-weight: 700;
}

header .file-name input:focus {
  border-bottom: 1px solid #ddd;
}

header .file-name p {
  line-height: 2.4;
  font-size: 16px;
  font-weight: 700;
}


header .right-header {
  margin-left: auto;
  display: flex;
  align-items: center;
}

header .right-header li {
  /*float: right;*/
}

.profile-pic {
  margin-top: 0;
  cursor: pointer;
}

.profile-pic img {
  vertical-align: middle;
  width: 34px;
  height: 34px;
  object-fit: cover;
  border-radius: 50%;
}

/* .profile-pic span{
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #e3e8f5;
  display: inline-block;
  line-height: 36px;
  text-align: center;
  color:  #4684ff;
  font-size: 16px;
} */


main {
  padding-top: 80px;
  display: flex;
  height: 100vh;
  position: relative;
}

aside {
  padding: 40px 0;
  right: 0;
  width: 100px;
  /* border-left: 1px solid #e5e5e5; */
  height: calc(100vh - 108px);
  /*overflow: auto;*/
  position: relative;
}

.actions li {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.7);
  margin: 12px auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.actions li:not(.color-picker):hover,
.actions li:not(.color-picker).active {
  background-color: var(--primary-color);
}

.color-picker {
  padding: 10px;
}

.color-picker span {
  height: 28px;
  width: 28px;
  display: block;
  border: 3px solid #ffffff;
  border-radius: 50%;
}

.actions i {
  display: block;
  margin: auto;
  line-height: 0;
}

/*.actions .text {
padding: 16px;
}

.actions .arrow {
padding: 17px;
}

.actions .rectangle,
.actions .circle,
.actions .line {
padding: 14px;
}

.actions .pencil,
.actions .eraser {
padding: 15px;
}*/

section {
  width: calc(100% - 100px);
  padding: 15px 15px 30px;
  position: relative;
}

.image-container {
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.image-container.has-image {
  opacity: 1;
}

.image-container.error {
  opacity: 0.9;
  background-color: #fafafa;
  width: calc(100% - 40px);
  max-height: calc(100vh - 130px);
  left: 20px;
  top: 20px;
  border-radius: 5px;
}
section .editing-img {
  height: calc(100vh - 130px);
  max-width: 100%;
  margin: auto;
  display: block;
  object-fit: contain;
}

/* screencapture canvas styles */

.canvas-container {
  width: 100% !important;
  height: 100% !important;
}

.canvas-container .lower-canvas,
.canvas-container .upper-canvas {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
}

.main-loader {
  width: calc(100% - 40px);
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
  left: 20px;
  right: 20px;
  top: 100px;
  bottom: 20px;
  border-radius: 3px;
}

.loader {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  /* border: 4px solid transparent;
border-top-color: #6285ff; */
  position: relative;
  animation: spin 1.5s linear infinite;
  margin-bottom: 4px;
}

@keyframes spin {
  0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

.load {
  top: 0;
  left: 0;
  display: inline-block;
  width: 42px;
  height: 42px;
  position: absolute;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: #6285ff;
  transform: rotate(-103deg);
}

.load2 {
  transform: rotate(19deg);
}

.load3 {
  transform: rotate(135deg);
}

h4 {
  font-size: 21px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.27px;
  text-align: center;
  color: #545454;
  margin-top: 20px;
}

.image-saver {
  background-color: rgba(0, 0, 0, 0.63);
  background-blend-mode: overlay;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.image-saver span {
  font-size: 27px;
  font-weight: 700;
  letter-spacing: -0.34px;
  color: #ffffff;
}

.progress-circle {
  display: none;
}

.button.progress .progress-circle {
  border: solid 2px #ffffff;
  border-top-color: #cecece;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-right: 6px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.step {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  padding: 8px;
  text-align: center;
  background-color: rgba(201, 201, 201, 0.2);
  margin-left: 12px;
  cursor: pointer;
  transition: 0.3s ease;
}

.step:hover {
  background-color: var(--primary-color);
}

.step:hover svg path {
  fill: #fff;
  opacity: 1;
}

.custom-color-picker {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #ebeced;
  background-color: #ffffff;
  padding: 12px 15px;
  border-radius: 4px;
  width: 180px;
  position: fixed;
  z-index: 10;
  top: 134px;
  right: 82px;
  cursor: default;
}

.colors-list {
}

.colors-list span {
  width: 28px;
  height: 28px;
  border: 1px solid #efefef;
  border-radius: 50%;
  display: inline-block;
  background-color: #ddd;
  cursor: pointer;
  overflow: hidden;
  margin: 0 2px 2px;
}

.colors-list .custom-colors {
  background: url(../../public/imgs/custom-colors.png) no-repeat;
  background-size: 100%;
}

.colors-list span input {
  opacity: 0;
}

.custom-color-picker:before,
.custom-color-picker:after {
  content: "";
  width: 0;
  height: 0;
  border: 8px solid;
  position: absolute;
  margin-top: -8px;
  z-index: 1;
  right: -16px;
}

.custom-color-picker:before {
  border-color: transparent transparent transparent #888;
  top: 20px;
}

.custom-color-picker:after {
  border-color: transparent transparent transparent #fff;
  top: 20px;
}

.color-picker-wrp {
  background-color: #ffffff;
  padding: 0 10px;
  border-radius: 3px;
  width: 179px;
  position: relative;
  margin: 0 -16px;
}

.color-picker-wrp>div:nth-child(1) {
  display: none;
}

.color-picker-wrp>div:nth-child(2) {
  position: relative;
  width: 148px;
  height: 148px;
}

.color-picker-wrp>div:nth-child(3) {
  width: 116px;
  margin: 10px 0px 0px;
  border-radius: 3px;
  border: 1px solid #d3d9e5;
  display: inherit !important;
  position: relative;
}

.color-picker-wrp>div:nth-child(3) input {
  width: 100%;
  outline: none;
  font-size: 12px;
  color: #727c8f;
  font-family: "Lato", sans-serif;
  padding: 3px 0px 0px 37px;
  height: 28px;
  border: 0px;
  border-radius: 3px;
}

.color-picker-wrp>div:nth-child(3)>div:nth-child(2) {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  position: absolute;
  margin: 6px 0px 0px 10px;
  top: 0px;
}

.color-picker-wrp>div:nth-child(3)>div:nth-child(3) {
  width: 73px;
  height: 28px;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  border: 1px solid #6699ff;
  position: absolute;
  text-align: center;
  color: #6699ff;
  border-radius: 3px;
  margin: 0px 0px 0px 126px;
  padding: 6px 0px;
  top: 0px;
  cursor: pointer;
}

.color-bar {
  height: 148px;
  position: absolute;
  width: 10px;
  right: 6px;
  top: 0px;
}

.color-bar .hue-vertical>div>div {
  width: 14px !important;
  border-radius: 1px !important;
  height: 4px !important;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 2px;
  transform: translateX(-2px);
  background-color: transparent !important;
  border: 1px solid #ffffff !important;
  margin-top: -5px !important;
}

.saturation-white {
  cursor: crosshair;
}



/*tooltip changes*/
.actions li[data-tooltip]:after{
  content: attr(data-tooltip);
  position: absolute;
  right: 100%;
  font-size: 12px;
  top: 12px;
  margin-right: 7px;
  padding: 7px 20px;
  border-radius: 3px;
  background-color: #000;
  color: #fff;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
}

.actions li[data-tooltip]:before{
  opacity: 0;
  visibility: hidden;
  content: '';
  position: absolute;
  left: -7px;
  top: 20px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #000;
  clear: both;
  transition: 0.3s ease;
}
.actions li:hover:after,.actions li:hover:before{
  opacity: 1;
  visibility: visible;
}

.disable-pointer {
  pointer-events:none;
}
